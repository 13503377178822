import * as React from 'react';
import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import TagFacesIcon from '@mui/icons-material/TagFaces';
import {config} from './config';
import Box from '@mui/material/Box';
import useFetch from './hooks/useFetch';
import useAlert from './hooks/useAlert';
import Switch from '@mui/material/Switch';

const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
}));

export default function InputList(props: any) {
    const request = useFetch();
    const [checked, setChecked] = React.useState(false);
    const {setAlert} = useAlert();
    const deleteInput = async (id: number) => {
	const body = JSON.stringify({id});
	const headers = {'Content-Type': "application/json"}
	try {
	    const response = await request(config.DeleteInput, {method: 'DELETE', credentials:"include", mode:'cors', body, headers})
	    props.fetchInputs();
	} catch (e:any) {
	    //catch rejection
	    setAlert(e.message, 3);
	}
    }


    return (
	<><Box sx={{ display:'flex', justifyContent: 'flex-end'}}><Switch checked={checked} onChange={()=>{setChecked(!checked)}}/></Box>
	    <Box
		sx={{
		    display: 'flex',
		    justifyContent: 'flex-start',
		    position: 'relative',
		    flexWrap: 'wrap',
		    listStyle: 'none',
		    p: 0.5,
		    m: 2,
		}}
		component="ul"
	    >
		{props.inputs.length && props.inputs.map((data: Input) => {
		    const edit:any = {};
		    if (checked) {
			edit["onDelete"] = () => {deleteInput(data.id)};
		    }
		    return (
			<ListItem key={data.id}>
			    <Chip
				label={data.label + ' ' + data.volume + 'oz ' + data.percentage + '%'}
				{...edit}
				onClick={() => {props.addConsumed(data.id)}}
			    />
			</ListItem>
		    );
		})}
	    </Box></>
    );
}
