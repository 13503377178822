import * as React from 'react';
import Radio from '@mui/material/Radio';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import {useAuth} from './AuthHook';
import {config} from './config';
import {router} from './App';
import useFetch from './hooks/useFetch';
import useAlert from './hooks/useAlert';

import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import {
	invalidEmail,
	invalidWeight,
	invalidGender,
	invalidPassword,
	invalidMetabolicRate,
	invalidBacInflection,
} from './Validate';



export default function Register() {
	const {signin} = useAuth();

	const [email, setEmail] = React.useState('');
	const [weight, setWeight] = React.useState(175);
	const [gender, setGender] = React.useState('m');
	const [metabolicRate, setMetabolicRate] = React.useState(.015);
	const [bacInflection, setBACInflection] = React.useState(.07);
	const [password, setPassword] = React.useState('');
	const [confirmPassword, setConfirmPassword] = React.useState('');

	const request = useFetch();
	const {setAlert} = useAlert();

	const [showPassword, setShowPassword] = React.useState(false);

	const handleClickShowPassword = () => setShowPassword((show) => !show);

	const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
	};

	const validate = (): boolean => {
		let check = invalidPassword(password) || invalidEmail(email) || invalidGender(gender) || invalidWeight(weight) || invalidMetabolicRate(metabolicRate) || invalidBacInflection(bacInflection);

		if (check !== false) {
			setAlert(check,3);
			return false;
		}

		if (password !== confirmPassword) {
			setAlert('Passwords do not match',3);
			return false;
		}

		return true;
	}

	const registerUser = async () => {
		if (validate()) {
			const body = JSON.stringify({email: email, password: password, weight: weight, gender: gender, bac_inflection: bacInflection, metabolic_rate: metabolicRate});
			const headers = {'Content-Type': 'application/json'}

			try {
				const response = await request(config.Register, {method:'POST', credentials:"include", mode: 'cors', body, headers });
				signin(await response.json());
				router.navigate('/');
			} catch (e:any) {
				//catch rejection
				setAlert(e.message, 3);
			}
		}
	}

	return (
		<Stack>
			<TextField
				id="email"
				label="Email"
				value={email}
				onChange={(e) => setEmail(e.target.value)}
				sx={{width: '25ch', my:1}}
			/>
			<FormControl sx={{ my: 1, width: '25ch' }} variant="outlined">
				<InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
				<OutlinedInput
					id="outlined-adornment-password"
					type={showPassword ? 'text' : 'password'}
					value={password}
					onChange={(e) => setPassword(e.target.value)}
					endAdornment={
						<InputAdornment position="end">
							<IconButton
								aria-label="toggle password visibility"
								onClick={handleClickShowPassword}
								onMouseDown={handleMouseDownPassword}
								edge="end"
							>
								{showPassword ? <VisibilityOff /> : <Visibility />}
							</IconButton>
						</InputAdornment>
					}
					label="Password"
				/>
			</FormControl>
			<FormControl sx={{ my: 1, width: '25ch' }} variant="outlined">
				<InputLabel htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
				<OutlinedInput
					id="outlined-adornment-password"
					type={showPassword ? 'text' : 'password'}
					value={confirmPassword}
					onChange={(e) => setConfirmPassword(e.target.value)}
					endAdornment={
						<InputAdornment position="end">
							<IconButton
								aria-label="toggle password visibility"
								onClick={handleClickShowPassword}
								onMouseDown={handleMouseDownPassword}
								edge="end"
							>
								{showPassword ? <VisibilityOff /> : <Visibility />}
							</IconButton>
						</InputAdornment>
					}
					label="Password"
				/>
			</FormControl>

			<RadioGroup
				row
				value={gender}
				onChange={(e) => setGender(e.target.value)}
				sx={{width: '25ch', mx:2, my:1}}
			>
				<FormControlLabel value="f" control={<Radio />} label="Female" />
				<FormControlLabel value="m" control={<Radio />} label="Male" />
			</RadioGroup>
			<TextField
				id="weight"
				value={weight}
				onChange={(e) => setWeight(parseInt(e.target.value))}
				label="Weight"
				type="number"
				sx={{width: '25ch', my:1}}
			/>
			<TextField
				id="bacInflection"
				value={bacInflection}
				onChange={(e) => setBACInflection(parseFloat(e.target.value))}
				label="BAC Inflection"
				type="number"
				inputProps={{
					step: .01,
				}}
				sx={{width: '25ch', my:1}}
			/>
			<TextField
				id="metabolicRate"
				value={metabolicRate}
				onChange={(e) => setMetabolicRate(parseFloat(e.target.value))}
				label="Metabolised BAC per hour"
				type="number"
				inputProps={{
					step: .001,
				}}
				sx={{width: '25ch', my:1}}
			/>
			<Button
				onClick={registerUser}
				sx={{width: '25ch', mt:1}}
				variant="contained">register</Button>
		</Stack>
	);
}
