import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Liquor from '@mui/icons-material/Liquor';
import {router} from './App';
import {userContext, useAuth} from './AuthHook';

function ResponsiveAppBar() {
	const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
	const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
	const {user} = React.useContext(userContext);
	const {signout} = useAuth();

	const loggedIn = user.weight !== 0;

	const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElNav(event.currentTarget);
	};

	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
	};

	const goTo = (path: string)  => {
		closeMenu();
		router.navigate(path);
	}

	const closeMenu = () => {
		if(anchorElNav) {
			setAnchorElNav(null);
		}
		if(anchorElUser) {
			setAnchorElUser(null);
		}
	}

	return (
		<AppBar position="static">
			<Toolbar disableGutters sx={{p: 1}}>
				<Liquor sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
				<Typography
					variant="h6"
					noWrap
					component="a"
					sx={{
						mr: 2,
							display: { xs: 'none', md: 'flex' },
							fontFamily: 'monospace',
							fontWeight: 700,
							letterSpacing: '.3rem',
							color: 'inherit',
							textDecoration: 'none',
					}}
				>
					Moderatus
				</Typography>
		{ !loggedIn ? <div/> :
		<Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
			<IconButton
				size="large"
				aria-label="account of current user"
				aria-controls="menu-appbar"
				aria-haspopup="true"
				onClick={handleOpenNavMenu}
				color="inherit"
			>
				<MenuIcon />
			</IconButton>
			<Menu
				id="menu-appbar"
				anchorEl={anchorElNav}
				anchorOrigin={{
					vertical: 'bottom',
						horizontal: 'left',
				}}
				keepMounted
				transformOrigin={{
					vertical: 'top',
						horizontal: 'left',
				}}
				open={Boolean(anchorElNav)}
				onClose={handleCloseNavMenu}
				sx={{
					display: { xs: 'block', md: 'none' },
				}}
			>
				<MenuItem key='config' onClick={()=>{goTo('/update')}}>
					<Typography textAlign="center">Config</Typography>
				</MenuItem>
				<MenuItem key='history' onClick={()=>{goTo('/history')}}>
					<Typography textAlign="center">History</Typography>
				</MenuItem>
				<MenuItem key='home' onClick={()=>{goTo('/')}}>
					<Typography textAlign="center">Home</Typography>
				</MenuItem>
				<MenuItem key='logout' onClick={() => { closeMenu(); signout();}}>
					<Typography textAlign="center">Logout</Typography>
				</MenuItem>
			</Menu>
	</Box>}
		<Liquor sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
		<Typography
			variant="h5"
			noWrap
			component="a"
			sx={{
				mr: 2,
					display: { xs: 'flex', md: 'none' },
					flexGrow: 1,
					fontFamily: 'monospace',
					fontWeight: 700,
					letterSpacing: '.3rem',
					color: 'inherit',
					textDecoration: 'none',
			}}
		>
			Moderatus
		</Typography>
		{!loggedIn ? <div/> :
		<Box sx={{ flexGrow: 1, justifyContent: 'flex-end', display: { xs: 'none', md: 'flex' } }}>
			<Button
				key='logout'
				onClick={() => { closeMenu(); signout();}}
				sx={{ my: 2, color: 'white', display: 'block' }}>
				Logout
			</Button>
			<Button
				key='config'
				onClick={()=>{goTo('/update')}}
				sx={{ my: 2, color: 'white', display: 'block' }}
			>
				Config
			</Button>
			<Button
				key='history'
				onClick={()=>{goTo('/history')}}
				sx={{ my: 2, color: 'white', display: 'block' }}
			>
				History
			</Button>
			<Button
				key='home'
				onClick={()=>{goTo('/')}}
				sx={{ my: 2, color: 'white', display: 'block' }}
			>
				Home
			</Button>
	</Box>}
</Toolbar>
			</AppBar>
		);
}
export default ResponsiveAppBar;
