import * as React from 'react';
import './styles/InputForm.scss';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import {config} from './config';
import useFetch from './hooks/useFetch';
import useAlert from './hooks/useAlert';
import InputAdornment from '@mui/material/InputAdornment';
import IngredientList from './IngredientList';

interface InputFormProps {
	fetchInputs: () => Promise<void>
}

export default function InputForm(props: InputFormProps) {
	const label = React.useRef<HTMLInputElement>(null);
	const volume = React.useRef<HTMLInputElement>(null);
	const percentage = React.useRef<HTMLInputElement>(null);
	const [ingredients, setIngredients] = React.useState<{volume:number, percentage:number}[]>([]);
	const request = useFetch();
	const {setAlert} = useAlert();
	const sx = {mb:2, display:'block'}

	const deleteIngredient = (index:number) => {
		setIngredients([...ingredients.slice(0,index), ...ingredients.slice(index + 1)]);
	}


	const addIngredient = () => {

		if (!validateVolume || !validatePercentage) {
			return;
		}
		
		const ingredient = {
			volume: Number(volume.current?.value),
			percentage: Number(percentage.current?.value),
		};

		setIngredients([...ingredients, ingredient]);
		
	}

	const validateLabel = () => {

		if (label.current?.value.length === 0) {
			setAlert('Label Field Blank', 3);
			return false;
		}

		return true;

	}

	const validateVolume = () => {

		if (volume.current?.value.length === 0) {
			setAlert('Volume Field Blank', 3);
			return false;
		}

		if (isNaN(parseFloat(volume.current?.value ?? ''))) {
			setAlert('Volume field is not a number', 3);
			return false;
		}

		return true;

	}


	const validatePercentage = () => {

		if (percentage.current?.value.length === 0) {
			setAlert('Percentage Field Blank', 3);
			return false;
		}

		if (isNaN(parseFloat(percentage.current?.value ?? ''))) {
			setAlert('Percentage field is not a number', 3);
			return false;
		}

		return true;

	}

	const validate = () => {
		return validateLabel() && validatePercentage() && validateVolume();
	}


	const prepareData = () => {
		if (ingredients.length) {
			let vol = 0;
			let alc = 0;
			for(let i = 0; i < ingredients.length; i++){
				let v = ingredients[i].volume;
				vol += v;
				alc += ingredients[i].percentage * v;
			}

			return {
				label: label.current?.value,
				volume: vol,
				percentage: Math.round((alc/vol)*10) / 10
			};
		}

		return {
			label: label.current?.value,
			volume: volume.current?.value,
			percentage: percentage.current?.value,
		};
	}

	const submitInput = async (drink?:any) => {
		let data = drink;

		if(data === undefined) {

			if (!validate()) {
				return;
			}

			data = {
				label: label.current?.value,
				volume: volume.current?.value,
				percentage: percentage.current?.value,
			};

		}
		
		const body = JSON.stringify(data);
		const headers = {'Content-Type': 'application/json'}
		try {
			await request(config.PostInput, {method:'POST', body, headers, credentials: 'include'});
			props.fetchInputs();
			
			if(label.current && volume.current && percentage.current) {
				label.current.value = '';
				volume.current.value = '';
				percentage.current.value = '';
			}

			setIngredients([]);

			setAlert(data.label + ' Added', 0);
		} catch (e:any) {
			//catch rejection
			setAlert(e.message, 3);
		}
	};

	const updateForm = (children:any) => {
		if(ingredients.length) {
			const data = prepareData();
			return (<><Box>
				<Paper elevation={10} sx={{display:'inline-block', px:1, mb:1}}>
					<Typography sx={{m:1, fontWeight:'light', fontStyle:'oblique'}}>* ingredients are not labeled</Typography>
					{children}
				</Paper></Box>
				<IngredientList deleteIngredient={deleteIngredient} ingredients={ingredients}/>
				<Box sx={{p:1, display:'inline-flex', alignItems:'center'}}>
					<Typography sx={{display:'inline', fontWeight:'bold'}}>{`${data.label} ${data.volume}oz ${data.percentage}%`}</Typography>
					<Button onClick={() =>{submitInput(data)}} variant="contained" sx={{display:'inline', ml:1}}>Submit</Button>
				</Box></>)
		}

		return children;

	}

	React.useEffect(() => {
		label.current?.focus();
	}, [])

	return (
		<Box>
			<TextField
				label="Label"
				sx={sx}
				inputRef={label}
			/>
			{ updateForm(<><TextField
				sx={sx}
				label="Volume"
				InputProps={{endAdornment:<InputAdornment position="end">oz</InputAdornment>}}
				style={{width:'160px'}}
				inputRef={volume}
			/>
				<TextField
					label="Percentage"
					sx={sx}
					InputProps={{endAdornment:<InputAdornment position="end">%</InputAdornment>}}
					style={{width:'160px'}}
					inputRef={percentage}
				/>
				<Button
					sx={sx}
					onClick={addIngredient}
					variant="contained">Add Ingredient</Button></>)}
			{!ingredients.length && <Button
				sx={sx}
				onClick={() =>{ submitInput() }}
				variant="contained">Submit</Button>}
		</Box>
	);
} 
