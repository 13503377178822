const gramsPerOunce = 23.3;
const femaleConstant = .55;
const maleConstant = .68;
const gramsPerLb = 454;

export const calculateWidmark = (grams: number, elapsedTime: number, metabolicRate: number, weight: number, gender: string): number => {
	if (grams === 0) {
		return 0;
	}
	const genderConstant = gender === 'm' ? maleConstant : femaleConstant;
	const bac = grams / (weight * gramsPerLb * genderConstant) * 100;

	return Math.max((Math.round((bac - (elapsedTime * metabolicRate))*1000) / 1000), 0);
}

const filterDrinks = (consumed: Consumed) => {
	return consumed.percentage !== undefined && consumed.percentage > 0;
}

export const getDuration = (grams: number, metabolicRate: number, weight: number, gender: string): number => {
	return calculateWidmark(grams, 0, metabolicRate, weight, gender)/metabolicRate * 60 * 60 * 1000;
}

export const filterMetabolized = (consumedList: Consumed[], metabolicRate: number, weight: number, gender: string): any => {
	consumedList.filter(filterDrinks)
	if (consumedList.length === 0) {
		//no alcoholic drinks in recent past
		return {percent: 0, consumed: []};
	}

	let startTime = new Date(consumedList[0].created_at);
	let index = 0;
	let duration = 0;

	for(let j = 0; j < consumedList.length; j++) {
		let drink = consumedList[j];
		let gramsA = drink.volume * (drink.percentage/100) * gramsPerOunce;
		duration = getDuration(gramsA, metabolicRate, weight, gender);
		let time = startTime.getTime() + getDuration(gramsA, metabolicRate, weight, gender); //ms
		if (time < Date.now()) {
			//this drink has been metabolized
			if (j === (consumedList.length -1)) {
				return {percent: 0, consumed: []};
			}
			index = j + 1;
			startTime = new Date(time);
		} else {
			//this drink has not been metabolized
			break;
		}
	}

	const percent = (startTime.getTime() + duration - Date.now()) / duration;

	return { percent, consumed: consumedList.slice(index)};
}

export const calculateBAC = (drinkList: Consumed[], metabolicRate: number, weight: number, gender: string) : number => {
    return calculateBACAtUnixTime(drinkList, metabolicRate, weight, gender, new Date().getTime());
}

export const calculateBACAtUnixTime = (drinkList: Consumed[], metabolicRate: number, weight: number, gender: string, ut: number) : number => {
	if (drinkList.length === 0) {
		return 0;
	}
	let totalAlcohol = 0;
	let startTime = new Date(drinkList[0].created_at);

	drinkList.forEach( drink => {
		const elapsedHours = (new Date(drink.created_at).getTime() - startTime.getTime()) / 1000 / 60 / 60;
		if (calculateWidmark(totalAlcohol, elapsedHours, metabolicRate, weight, gender) > 0) {
			totalAlcohol += drink.volume * (drink.percentage/100) * gramsPerOunce;
		} else {
			//Beginning or there has been sobering interval in the provided drink list
			startTime = new Date(drink.created_at);
			totalAlcohol = drink.volume * (drink.percentage/100) * gramsPerOunce;
		}
	})

	const elapsedHours = (ut - startTime.getTime()) / 1000 / 60 / 60; //hours

	return calculateWidmark(totalAlcohol, elapsedHours, metabolicRate, weight, gender);
}


export const checkStatus = (consumedList: Consumed[], metabolicRate: number, weight: number, gender: string): number => {
	return calculateBAC(consumedList.filter(filterDrinks), metabolicRate, weight, gender);
}

export const getMessage = (bac: number, bacInflection: number, painFloor: number): string => {
	let message = "";

	if (bac < .04) {
		message = "Good to go";
	} else if (bac < bacInflection) {
		message = "Optimally drunk, No reason to drink more";
	} else if (bac < painFloor) {
		message = "Impaired; you're slurring";
	} else {
		message = "Everyone is in pain";
	}
	return message
}

export const getBGColor = (bac: number, bacInflection: number, metabolicRate: number, weight: number, gender: string): string => {
	const oneDrinkIncrease = calculateWidmark(14, 0, metabolicRate, weight, gender);

	if (bac <= bacInflection - oneDrinkIncrease) {
		return '#2dc937';
	} else if (bac <= bacInflection + oneDrinkIncrease) {
		return '#e7b416';
	} else if (bac <= Math.max(bacInflection + .03)) {
		return '#db7b2b';
	} else {
		return '#cc3232';
	}
}

export const hoursToTargetBAC = (bac: number, goal: number, metabolicRate: number): number => {
	if (bac < goal) {
		return 0;
	}

	return Math.round((bac - goal) / metabolicRate * 10)/10;
}

export const recommendWait = (bac: number, bacInflection: number, metabolicRate: number, gender: string, weight: number): number => {
	const oneDrinkIncrease = getDuration(14, metabolicRate, weight, gender);
	return hoursToTargetBAC(bac, bacInflection - oneDrinkIncrease, metabolicRate);
}


const diff_hours = (dt2: any, dt1: any) => {
	var diff =(dt2.getTime() - dt1.getTime()) / 1000;
	diff /= (60 * 60);
	return Math.abs(Math.round(diff));
}

export const generateCoordinates = (consumed: Consumed[], metabolicRate: number, weight: number, gender: string, length: number, units: string): any[] => {
	let points: any[] = [];
	let before: Consumed[] = [];
	let soberTime = new Date(consumed[0].created_at);
	let j = 1;

	for(let i = 0; i < consumed.length; i++){
		const {created_at} = consumed[i];
		const now = new Date(created_at);
		if (now > soberTime) {
		    points.push({id:(j++).toString(), key: soberTime, data:0});
		}
		const sub = consumed.slice(0, i + 1);
		const bacPre = calculateBACAtUnixTime(before, metabolicRate, weight, gender, now.getTime());
		const bacPost = calculateBACAtUnixTime(sub, metabolicRate, weight, gender, now.getTime());
		points.push({id:(j++).toString(), key: now, data:bacPre});
		points.push({id:(j++).toString(), key: now, data:bacPost});

		const msToSober = hoursToTargetBAC(bacPost, 0, metabolicRate) * 60 * 60 * 1000;
		soberTime = new Date(now);
		soberTime.setTime(soberTime.getTime() + msToSober);
		if (i+1 === consumed.length) {
		    points.push({id:(j++).toString(), key: soberTime, data:0});
		}

		before = sub;
	}


	return points;
}
