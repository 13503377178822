import * as React from 'react';
import Radio from '@mui/material/Radio';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import {userContext} from './AuthHook';
import {useAuth} from './AuthHook';
import {config} from './config';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import useFetch from './hooks/useFetch';
import useAlert from './hooks/useAlert';

import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import {
	invalidEmail,
	invalidWeight,
	invalidGender,
	invalidPassword,
	invalidMetabolicRate,
	invalidBacInflection,
} from './Validate';

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

function a11yProps(index: number) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

export default function Update() {
	const [value, setValue] = React.useState(0);
	const [showPassword, setShowPassword] = React.useState(false);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	const {user} = React.useContext(userContext);
	const {signin} = useAuth();
	const loggedIn = user.weight !== 0;
	const request = useFetch();
	const {setAlert} = useAlert();

	const [email, setEmail] = React.useState('');
	const [weight, setWeight] = React.useState(loggedIn ? user.weight : 175);
	const [gender, setGender] = React.useState(loggedIn ? user.gender : 'm');
	const [metabolicRate, setMetabolicRate] = React.useState(loggedIn ? user.metabolic_rate : .015);
	const [bacInflection, setBACInflection] = React.useState(loggedIn ? user.bac_inflection : .07);
	const [password, setPassword] = React.useState('');
	const [confirmPassword, setConfirmPassword] = React.useState('');

	const handleClickShowPassword = () => setShowPassword((show) => !show);

	const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
	};

	React.useEffect(() => {
		setEmail(user.email);
		setWeight(user.weight);
		setGender(user.gender);
		setMetabolicRate(user.metabolic_rate);
		setBACInflection(user.bac_inflection);
	}, [user]);

	React.useEffect(() => {},[user])

	const updateUser = async () => {
		if(validateUpdateUser()) {
			const body = JSON.stringify({email: email, weight: weight, gender: gender, bac_inflection: bacInflection, metabolic_rate: metabolicRate});
			const headers = {'Content-Type': 'application/json'}

			try {
				const response = await request(config.UpdateUser, {method:'POST', credentials:"include", mode: 'cors', body, headers });
				signin(await response.json());
				setAlert('User Updated', 0);
			} catch (e:any) {
				//catch rejection
				setAlert(e.message, 3);
			}
		}

	}

	const updatePassword = async () => {
		if(validateUpdatePassword()) {
			const body = JSON.stringify({password: password});
			const headers = {'Content-Type': 'application/json'}
			request(config.UpdatePassword, {method:'POST', credentials:"include", mode: 'cors', body, headers});
			setAlert('Password Updated', 0);
			setPassword('');
			setConfirmPassword('');
			setShowPassword(false);
		}
	}

	const validateUpdatePassword = (): boolean => {
		let check = invalidPassword(password);

		if (check !== false) {
			setAlert(check,3);
			return false;
		}

		if (password !== confirmPassword) {
			setAlert('Passwords do not match',3);
			return false;
		}

		return true;
	}

	const validateUpdateUser = (): boolean => {
		let check = invalidEmail(email) || invalidGender(gender) || invalidWeight(weight) || invalidMetabolicRate(metabolicRate) || invalidBacInflection(bacInflection);

		if (check !== false) {
			setAlert(check,3);
			return false;
		}

		return true;

	}

	return (
		<Box sx={{ width: '100%' }}>
			<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
				<Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
					<Tab label="Settings" {...a11yProps(0)} />
					<Tab label="New Password" {...a11yProps(1)} />
				</Tabs>
			</Box>
			<TabPanel value={value} index={0}>
				<FormControl>
					<TextField
						id="email"
						sx={{mb:1}}
						label="Email"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
					/>
					<RadioGroup
						row
						sx={{mb:1}}
						value={gender}
						onChange={(e)=> setGender(e.target.value)}
					>
						<FormControlLabel value="f" control={<Radio />} label="Female" />
						<FormControlLabel value="m" control={<Radio />} label="Male" />
					</RadioGroup>
					<TextField
						id="weight"
						sx={{mb:1}}
						label="Weight"
						type="number"
						value={weight}
						variant="standard"
						onChange={(e) => setWeight(parseInt(e.target.value))}
					/>
					<TextField
						id="bacInflection"
						sx={{mb:1}}
						label="BAC Inflection"
						type="number"
						value={bacInflection}
						inputProps={{
							step: .01,
						}}
						variant="standard"
						onChange={(e) => setBACInflection(parseFloat(e.target.value))}
					/>
					<TextField
						id="metabolicRate"
						sx={{mb:1}}
						label="Metabolised BAC per hour"
						type="number"
						value={metabolicRate}
						inputProps={{
							step: .001,
						}}
						variant="standard"
						onChange={(e) => setMetabolicRate(parseFloat(e.target.value))}
					/>
					<Button
						onClick={updateUser}
						variant="contained">update</Button>
				</FormControl>
			</TabPanel>
			<TabPanel value={value} index={1}>
				<Box>
				<FormControl sx={{ m: 1, width: '25ch', display: 'block' }} variant="outlined">
				<InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
				<OutlinedInput
					id="outlined-adornment-password"
					type={showPassword ? 'text' : 'password'}
					value={password}
					onChange={(e) => setPassword(e.target.value)}
					endAdornment={
						<InputAdornment position="end">
							<IconButton
								aria-label="toggle password visibility"
								onClick={handleClickShowPassword}
								onMouseDown={handleMouseDownPassword}
								edge="end"
							>
								{showPassword ? <VisibilityOff /> : <Visibility />}
							</IconButton>
						</InputAdornment>
					}
					label="Password"
				/>
			</FormControl>
					<FormControl sx={{ m: 1, width: '25ch', display: 'block' }} variant="outlined">
				<InputLabel htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
				<OutlinedInput
					id="outlined-adornment-password"
					type={showPassword ? 'text' : 'password'}
					value={confirmPassword}
					onChange={(e) => setConfirmPassword(e.target.value)}
					endAdornment={
						<InputAdornment position="end">
							<IconButton
								aria-label="toggle password visibility"
								onClick={handleClickShowPassword}
								onMouseDown={handleMouseDownPassword}
								edge="end"
							>
								{showPassword ? <VisibilityOff /> : <Visibility />}
							</IconButton>
						</InputAdornment>
					}
					label="Password"
				/>
			</FormControl>
					<Button
						sx={{ m: 1, width: '25ch', display: 'block' }}
						onClick={updatePassword}
						variant="contained">
						Update Password
					</Button>
			</Box>
				</TabPanel>
		</Box>
				);
				}
