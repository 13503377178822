import React, { useEffect, useState } from "react"
import {config} from './config';
import {userContext} from './AuthHook';
//import './css/Profile.css';
import Dashboard from './Dashboard';
import Menu from './Menu';
import Box from '@mui/material/Box';
import useFetch from './hooks/useFetch';
import useAlert from './hooks/useAlert';


export default function Profile() {

	const {user} = React.useContext(userContext);

	const [consumed, setConsumed] = useState<Consumed[]>([]);
	const [count, setCount] = useState<number>(0);
	const request = useFetch();
	const {setAlert} = useAlert();

	const compareUTC = (x: Consumed, y: Consumed): number => {
		if (x.created_at < y.created_at) {
			return -1;
		}

		if (x.created_at > y.created_at) {
			return 1;
		}

		return 0;
	}

	const updateConsumed = (consumed: Consumed[]) : void => {
		setConsumed(consumed.sort(compareUTC));
	}


	const fetchConsumed = async () => {
		try {
			const response = await request(config.GetConsumed, {credentials:'include'});
			updateConsumed(await response.json());
		} catch (e:any) {
			//catch rejection
			setAlert(e.message, 3);
		}
	}



	useEffect(() => {
			fetchConsumed();

			const intervalID = setInterval(()=> {setCount(count + 1)}, 1000 * 60 * 5);

			return () => clearInterval(intervalID);
			},[]);


	return (
			<Box sx={{height: '90vh', width: 1}}>
			<Box sx={{height: .5, width: 1, position: 'relative'}}>
			<Dashboard {...user} consumed={consumed}/>
			</Box>
			<Box sx={{height: .5, width: 1}}>
			<Menu consumed={consumed} fetchConsumed={fetchConsumed}/>
			</Box>
			</Box>
	       );
}
