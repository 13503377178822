import React from "react"
import { LineChart, LineSeries, Line } from 'reaviz';
import {generateCoordinates} from './Utils';
import {userContext} from './AuthHook';
import Box from '@mui/material/Box';

const abbreviate = (s: string): string => {
	const tmp = s.trim().split(' ');
	return tmp.map(x=>x[0].toUpperCase()).join('');
}

const generateData = (consumed: Consumed[], metabolic_rate: number, weight: number, gender: string, length: number, units: string) => {
	if (consumed.length ===0) {
		return [];
	}

	return generateCoordinates(consumed, metabolic_rate, weight, gender, length, units);
}

const HistoryGraph = (props: any) => {

	const {user} = React.useContext(userContext);
	const data = generateData(props.consumed, user.metabolic_rate, user.weight, user.gender, props.length, props.units);

	//if beginning is before window start
	if (props.start < data[0]['key']) {
	    data.unshift({id:"0", key: props.start, data:0});
	}
	//if end is after window end
	if (props.end > data[data.length - 1]['key']) {
	    data.push({id:data.length.toString(), key: props.end, data:0});
	}
	if (data.length === 0) {
		return <div/>;
	}

	return (<Box>
	    <div style={{
			width:'98vw',
			height:'50vh'
		}}>
		<LineChart
			data={data}
		    series={<LineSeries interpolation="linear" colorScheme="cybertron" line={<Line strokeWidth={4}/>}/>}
		/></div></Box>);
}

export default HistoryGraph;
