import * as React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import {useAuth} from './AuthHook';
import {config} from './config';
import useFetch from './hooks/useFetch';
import useAlert from './hooks/useAlert'

import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

export default function Login() {
	const {signin} = useAuth();
	const [password, setPassword] = React.useState('');
	const [email, setEmail] = React.useState('');
	const request = useFetch();
	const {setAlert} = useAlert();
	const sx = {mb:2, display:'block'}

	const [showPassword, setShowPassword] = React.useState(false);

	const handleClickShowPassword = () => setShowPassword((show) => !show);

	const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
	};

	const login = async () => {
		const body = JSON.stringify({email, password});
		const headers = {'Content-Type': 'application/json'}
		try {
			const response = await request(config.Login, {method:'POST', credentials:"include", mode: 'cors', body, headers });
			signin(await response.json());
		} catch (e:any) {
			//catch rejection
			setAlert(e.message, 3);
		}
	};

	return (
		<Stack>
			<TextField
				id="outlined-required"
				label="Email"
				value={email}
				onChange={(e) => setEmail(e.target.value)}
				sx={{width: '25ch', m:1}}
			/>
			<FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
				<InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
				<OutlinedInput
					id="outlined-adornment-password"
					value={password}
					onChange={(e) => setPassword(e.target.value)}
					type={showPassword ? 'text' : 'password'}
					endAdornment={
						<InputAdornment position="end">
							<IconButton
								aria-label="toggle password visibility"
								onClick={handleClickShowPassword}
								onMouseDown={handleMouseDownPassword}
								edge="end"
							>
								{showPassword ? <VisibilityOff /> : <Visibility />}
							</IconButton>
						</InputAdornment>
					}
					label="Password"
				/>
			</FormControl>
			<Button
				onClick={login}
				sx={{width: '25ch', m:1}}
				variant="contained">Login</Button>
		</Stack>
	);
}
