// AuthContext.js
import { createContext, useState } from 'react';

const ALERT_TIME = 5000;
const initialState = {
text: '',
type: 1,
};

const AlertContext = createContext({
		...initialState,
		setAlert: (text:string, type:number): void => {},
		clearAlert: (): void => {},
		});

export const AlertProvider = ({ children }: any):any => {
	const [text, setText] = useState<string>('');
	const [type, setType] = useState<number>(1);

	const setAlert = (text: string, type:number): void => {
		setText(text);
		setType(type);
	};

	const clearAlert = (): void => {
		setText('');
		setType(1);
	};

	return (
			<AlertContext.Provider
			value={{
			text,
			type,
			setAlert,
			clearAlert
			}}
			>
			{children}
			</AlertContext.Provider>
	       );
};

export default AlertContext;
