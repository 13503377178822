export const invalidEmail = (email: string | undefined) => {
	if(!email || email.length === 0) {
		return 'Invalid Email: empty';
	}

	return false;
}

export const invalidWeight = (weight: string | number | undefined) => {
	let w = Number(weight);
	if(isNaN(w) || w <= 0) {
		return 'Invalid Weight: ' + weight;
	}

	return false;
}

export const invalidGender = (gender: string | undefined) => {
	if(!gender || (gender !== 'm' && gender !== 'f')) {
		return 'Invalid Gender: ' + gender;
	}

	return false;
}

export const invalidMetabolicRate = (metabolicRate: string | number | undefined) => {
	let mb = Number(metabolicRate);
	if(isNaN(mb) || mb <= 0) {
		return 'Invalid Metabolic Rate: ' + metabolicRate;
	}

	return false;
}

export const invalidBacInflection = (bacInflection: string | number | undefined) => {
	let bi = Number(bacInflection);
	if(isNaN(bi) || bi <= 0) {
		return 'Invalid BAC Inflection: ' + bacInflection;
	}

	return false;
}

export const invalidPassword = (password: string | undefined) => {
	if(!password || password.length === 0) {
		return 'Invalid Password: empty';
	}

	return false;
}
