import React from "react"
import {config} from './config';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useFetch from './hooks/useFetch';
import useAlert from './hooks/useAlert';
import SpillIcon from './spill.svg';

export default function Consumed (props: any) {

	const request = useFetch();
	const {setAlert} = useAlert();

	const deleteConsumed = async (id: number) => {
		const body = JSON.stringify({id});
		const headers = {'Content-Type': "application/json"}
		try {
			const response = await request(config.DeleteConsumed, {method: 'DELETE', credentials:"include", mode:'cors', body, headers});
			setAlert('Drink deleted', 0);
			await props.fetchConsumed();
		} catch (e:any) {
			setAlert(e.messasge, 3);
		}
	}

	const halfVolume = async (volume: number, id: number) => {
		const v = Math.round((volume/2)*10) / 10
		if (v===0 || volume === .1) {
			deleteConsumed(id);
			return;
		}
		const body = JSON.stringify({volume: v, id});
		const headers = {'Content-Type': "application/json"}
		try {
			const response = await request(config.UpdateConsumed, {method: 'PUT', credentials:"include", mode:'cors', body, headers});
			setAlert('Drink volume halved', 0);
			await props.fetchConsumed();
		} catch (e:any) {
			setAlert(e.messasge, 3);
		}
	}


	function generateList() {
		return props.consumed.map( (c: Consumed) => {
				return (<ListItem
						key={c.id}
						secondaryAction={
						<>
							<IconButton sx={{mr: 1}} edge="end" aria-label="delete" onClick={() => {halfVolume(c.volume, c.id)}}>
						<Icon>
							<img src={SpillIcon}/>
						</Icon>
						</IconButton>
						<IconButton edge="end" aria-label="delete" onClick={() => {deleteConsumed(c.id)}}>
						<DeleteIcon/>
						</IconButton>
						</>
						}>
						<ListItemText
						primary={c.label}
						secondary={c.volume + ' oz.  ' + c.percentage + '%  ' + new Date(c.created_at).toLocaleTimeString([], {hour: 'numeric', minute: '2-digit'})}
						/>
						</ListItem>);});
	}


	return (props.consumed.length === 0 ? <div/> :
			<List>
			{generateList()}
			</List>
	       );
}
