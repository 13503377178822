import * as React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import {useAuth} from './AuthHook';
import {config} from './config';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Register from './Register';
import useFetch from './hooks/useFetch';
import useAlert from './hooks/useAlert';

export default function ForgotPasswordForm() {
	const [email, setEmail] = React.useState('');
	const request = useFetch();
	const {setAlert} = useAlert();

	const resetPassword = async () => {
		if (validate()) {
			const body = JSON.stringify({email});
			const headers = {'Content-Type': 'application/json'}
			try {
				request(config.ForgotPassword, {method:'POST', mode: 'cors', body, headers});
			} catch (e:any) {
				//catch rejection
				setAlert(e.message, 3);
			}
		}

	}

	const validate = (): boolean => {
		if (email.length === 0) {
			return false;
		}
		return true;

	}

	return (
			<Stack>
			<TextField
			id="email"
			label="Email"
			value={email}
			onChange={(e) => setEmail(e.target.value)}
			sx={{width: '25ch', m:1}}
			/>
			<Button
			onClick={resetPassword}
			disabled={validate()}
			sx={{width: '25ch', m:1}}
			variant="contained">Reset Password</Button>
			</Stack>
	       );
}
