import {useState, useEffect} from "react";
import { Snackbar, Alert, Box } from '@mui/material';
import useAlert from './hooks/useAlert';

const AlertPopup = () => {
	const { text, type, clearAlert } = useAlert();
	const [open, setOpen] = useState<boolean>(false);

	let tmp;
	switch (type) {
		case 0:
			tmp = 'success' as const;
			break;
		case 1:
			tmp = 'info' as const;
			break;
		case 2:
			tmp = 'warning' as const;
			break;
		case 3:
			tmp = 'error' as const;
			break;
	}

	useEffect(() => {
			if(text.length){
			setOpen(true);
			setTimeout(()=>{setOpen(false); setTimeout(clearAlert, 100)}, 3000);
			}
			}, [text]);
return (

		<Snackbar
		anchorOrigin={{ vertical:'top', horizontal:'center'}}
		open={open}
		>
		<Alert
		color={tmp}
		>
		{text}
		</Alert>

		</Snackbar>
       );
};

export default AlertPopup;
