import * as React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimeField } from '@mui/x-date-pickers/DateTimeField';
import HistoryGraph from './HistoryGraph';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import Box from '@mui/material/Box';
import NativeSelect from '@mui/material/NativeSelect';
import dayjs, {Dayjs} from 'dayjs';
import {config} from './config';
import useFetch from './hooks/useFetch';
import useAlert from './hooks/useAlert';

const History = (props:any) => {
    const tmp = new Date();
    const [length, setLength] = React.useState<number>(24);
    const [pole, setPole] = React.useState<boolean>(false);
    const [datetime, setDatetime] = React.useState<Dayjs | null>(dayjs(tmp.toISOString()));
    const [consumed, setConsumed] = React.useState<Consumed[]>([]);
    const [units, setUnits] = React.useState<string>('hours');
    const request = useFetch();
    const {setAlert} = useAlert();

    const start = new Date(datetime ? datetime.toString() : tmp);
    const end = new Date(datetime ? datetime.toString() : tmp);

    let interval = length * 60 * 60 * 1000;

    switch(units) {
	case "hours":
	    break;
	case "days":
	    interval *= 24;
	    break;
	default:
	    break;
    }

    if (pole) {
	end.setTime(end.getTime() + interval);
    } else {
	start.setTime(start.getTime() - interval);
    }

    const validate = () => {
	if (length < 1) {
	    return false;
	}
	if (datetime === null) {
	    return false;
	}
	return true;
    }

    const queryHistory = async () => {
	if (!validate()) {
	    return;
	}

	let dt = datetime!.format('YYYY-MM-DD HH:mm:ssZZ');
	dt = dt.substring(0, dt.length-2);

	const body = JSON.stringify({length: length, pole: pole ? 'start' : 'end', datetime: dt,  units: units});
	const headers = {'Content-Type': 'application/json'};
	try {
	    const response = await request(config.GetHistory, {method:'POST', body, headers, credentials: 'include'});
	    setConsumed(await response.json());
	} catch (e:any) {
	    setAlert(e.message, 3);
	}
    }

    return (
	<>
	    <Box sx={{p:1, justifyContent:'center', alignItems:'center', display:'flex'}}>
		<FormControlLabel
		    sx={{m:1, display:'inline-block'}}
		    control={<Switch checked={pole}  onChange={()=>{setPole(!pole)}}/>}
		    label={pole ? 'Start Time' : 'End Time'}/>
		<LocalizationProvider dateAdapter={AdapterDayjs}>
		    <DateTimeField
			sx={{m:1, display:'inline-block'}}
			value={datetime}
			onChange={(x)=> setDatetime(x)}
		    />
		</LocalizationProvider>
		<TextField
		    id="length"
		    variant='standard'
		    value={length}
		    onChange={(e) => setLength(parseInt(e.target.value))}
		    type="number"
		    sx={{width: '6ch', m:1, display:'inline-block'}}
		/>
		<FormControl>
		    <NativeSelect
			value={units}
			sx={{width: '8ch', m:1, display:'inline-block'}}
			onChange={(e)=> setUnits(e.target.value)}
			inputProps={{
			    name: 'units',
			}}
		    >
			<option value='hours'>hrs</option>
			<option value='days'>days</option>
		    </NativeSelect>
		</FormControl>
		<Button
		    onClick={queryHistory}
		    variant="contained">
		    Query
		</Button>
	    </Box>
	    { consumed.length && <HistoryGraph start={start} end={end} consumed={consumed}/> }
	</>
    );
}

export default History;
