import * as React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import {useAuth, userContext} from './AuthHook';
import {config} from './config';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Register from './Register';
import Login from './Login';
import Profile from './Profile';
import ForgotPasswordForm from './ForgotPasswordForm';
import useFetch from './hooks/useFetch';

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
		)}
	</div>
	);
}

function a11yProps(index: number) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

export default function Landing() {
	const {user} = React.useContext(userContext);
	const [value, setValue] = React.useState(0);
	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	return ( user.weight === 0 ? (
		<Box sx={{ width: '100%' }}>
			<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
				<Tabs variant='scrollable' value={value} onChange={handleChange} aria-label="basic tabs example">
					<Tab label="Login" {...a11yProps(0)} />
					<Tab label="Forgot Password" {...a11yProps(1)} />
					<Tab label="Register" {...a11yProps(2)} />
				</Tabs>
			</Box>
			<TabPanel value={value} index={0}>
				<Login />
			</TabPanel>
			<TabPanel value={value} index={1}>
				<ForgotPasswordForm />
			</TabPanel>
			<TabPanel value={value} index={2}>
				<Register />
			</TabPanel>
		</Box>) : (<Profile/>)
	);
}
