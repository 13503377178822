import {router} from '../App'
import {useAuth} from '../AuthHook';
import useAlert from './useAlert'

const useFetch = () => {	
    const {setAlert} = useAlert();
    const {signout} = useAuth();
    return (async (path: string, options: any):Promise<any> => {
	return new Promise(async (resolve, reject) => {
	    try {
		const response = await fetch(path, options)
		if (response.ok) {
		    resolve(response);

		} else if (response.status === 403 || response.status === 401) {
		    setAlert('Not Authorized', 3);
		    signout();
		    router.navigate('/');
		} else {
		    reject(await response.json());
		}
	    } catch (e:any) {
		reject(e);
	    }});
    })}

    export default useFetch;
