const serverDomain = 'https://moderatus.hartung.dev';

export const config = {
	PostInput: serverDomain + '/input',
	PostConsumed: serverDomain + '/consumed', 
	GetInput: serverDomain + '/input',
	GetConsumed: serverDomain + '/consumed',
	GetHistory: serverDomain + '/history',
	UpdateConsumed: serverDomain + '/consumed',
	DeleteInput: serverDomain + '/input',
	DeleteConsumed: serverDomain + '/consumed',
	UpdateUser: serverDomain + '/user',
	UpdatePassword: serverDomain + '/updatepassword',
	ForgotPassword: serverDomain + '/forgotpassword',
	Register: serverDomain + '/signup',
	Login: serverDomain + '/login'
}
