import React from "react"
import { BubbleChart, BubbleSeries, BubbleLabel, Bubble } from 'reaviz';
import {calculateWidmark, filterMetabolized, getDuration} from './Utils';
import {userContext} from './AuthHook';
import Box from '@mui/material/Box';

const abbreviate = (s: string): string => {
	const tmp = s.trim().split(' ');
	return tmp.map(x=>x[0].toUpperCase()).join('');
}

const generateData = (consumed: Consumed[], metabolic_rate: number, weight: number, gender: string) => {
	const result = filterMetabolized(consumed, metabolic_rate, weight, gender);
	if (result.consumed.length ===0) {
		return [];
	}
	return result.consumed.map((c: Consumed, i: number) => {
		let datum;
		if (i === 0) {
			datum = c.volume * c.percentage * result.percent ;
		} else {
			datum = c.volume * c.percentage;
		}
		return {key: abbreviate(c.label) + i, data: datum};
	});
}

const Bubbles = (props: {bgColor: string, consumed: Consumed[]}) => {

	const {user} = React.useContext(userContext);
	const data = generateData(props.consumed, user.metabolic_rate, user.weight, user.gender);

	if (data.length === 0) {
		return <div/>;
	}

	return (<Box sx={{width:'100%', height: '100%'}}>
		<BubbleChart
			data={data}
			series={<BubbleSeries bubble={<Bubble tooltip={<div/>}/>} label={<BubbleLabel animated={false}/>}/>}
		/></Box>);
}

export default Bubbles;
