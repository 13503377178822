import * as React from 'react';
import Bubbles from './Bubbles';
import NeedleGauge from './NeedleGauge';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import {checkStatus, getBGColor} from './Utils';
import {userContext} from './AuthHook';

//const generateMessages = (): any => {
//		const bac = stats.checkStatus(consumed);
//		const hoursToNoHangover = stats.hoursToTargetBAC(bac, .07);
//		const hoursToLegal = stats.hoursToTargetBAC(bac, .06);
//		const hoursToSober = stats.hoursToTargetBAC(bac, 0);
//		const hoursToNextDrink = stats.recommendWait(bac);
//		let tmp = stats.getBGColor(bac);
//		if (tmp !== bgColor) {
//			setBGColor(stats.getBGColor(bac));
//		}
//
//		return (<Box>{'BAC: ' + bac + '%'}<br/>{'Next Drink: ' + hoursToNextDrink + 'hr'}<br/>{'No Hangover: ' + hoursToNoHangover + 'hr'}<br/>{'Legal: ' + hoursToLegal + 'hr'}<br/>{'Sober: ' + hoursToSober + 'hr'}<br/>{ stats.getMessage(bac)}</Box>);
//
//	}


const Dashboard = (props: any) : any => {
	const {user} = React.useContext(userContext);
	const [checked, setChecked] = React.useState(false);
	const bac = checkStatus(props.consumed, user.metabolic_rate, user.weight, user.gender);
	let bac_string = bac.toString().slice(1)
	let bac_string_hundredth = bac_string.length
	if (bac_string.length === 0) {
		bac_string = '.000';
	} else {
	    bac_string.padEnd(4, '0');
	}
	const BGColor= getBGColor(bac, user.bac_inflection, user.metabolic_rate, user.weight, user.gender);

	return (<>
	    <Box sx={{zIndex:'tooltip', position: 'absolute', display:'flex', alignItems:'baseline', right: 0, mr:1}}>
	    <Typography color={BGColor} variant='h2' fontFamily={'Audiowide, sans-serif'}>{bac_string.substring(0,3)}</Typography>
		<Typography color={BGColor} variant='h4' fontFamily={'Audiowide, sans-serif'}>{bac_string[3]}</Typography></Box>
		<Box display='flex' alignItems='center' justifyContent='center' sx={{height: 1, width: 1, zIndex:'mobileStepper', position: 'static'}}>
			{(!checked ? (<NeedleGauge bac={bac}/>) :
			  (<Bubbles bgColor={BGColor} consumed={props.consumed} />))}
		</Box>
	    {/*<Box sx={{zIndex:'tooltip', position: 'absolute', right: 0, bottom: 0}}><Switch checked={checked} onChange={()=>{setChecked(!checked)}}/></Box>*/}</>
	       );

}

export default Dashboard;
