import InputForm from './InputForm';
import React, { useEffect } from "react"
import {config} from './config';
import InputList from './InputList';
import Typography from '@mui/material/Typography';
import Consumed from './Consumed';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import useFetch from './hooks/useFetch';
import useAlert from './hooks/useAlert';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
	<div
	    role="tabpanel"
	    hidden={value !== index}
	    id={`simple-tabpanel-${index}`}
	    aria-labelledby={`simple-tab-${index}`}
	    {...other}
	>
	    {value === index && (
		<Box sx={{ p: 3 }}>
		    <Typography>{children}</Typography>
		</Box>
	    )}
	</div>
    );
}

function a11yProps(index: number) {
    return {
	id: `simple-tab-${index}`,
	'aria-controls': `simple-tabpanel-${index}`,
    };
}



export default function Menu(props: any) {
    const [value, setValue] = React.useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
	setValue(newValue);
    };

    const [inputs, setInputs] = React.useState<Input[]>([]);
    const request = useFetch();
    const {setAlert} = useAlert();

    const addConsumed = async (id: number) => {
	const body = JSON.stringify({input_id: id});
	const headers = {'Content-Type': 'application/json'}
	try {
	    const response = await request(config.PostConsumed, {method: 'POST', body, headers, credentials: 'include'})
	    await props.fetchConsumed();
	} catch (e:any) {
	    //catch rejection
	    setAlert(e.message, 3);
	}
    }

    const fetchInputs = async () => {
	const response = await fetch(config.GetInput,{credentials:'include'});
	if (response.ok) {
	    setInputs(await response.json());
	}
    }

    useEffect(() => {
	fetchInputs()
    }, []);

    return (
	<Box sx={{ width: '100%' }}>
	    <Box sx={{ borderBottom: 1, borderColor: 'divider'}}>
		<Tabs value={value} onChange={handleChange} aria-label="basic tabs example" variant='fullWidth' textColor='primary'>
		    <Tab label="Consume" {...a11yProps(0)} />
		    <Tab label="New" {...a11yProps(1)} />
		    <Tab label={'Recent: ' + props.consumed.length} {...a11yProps(2)} />
		</Tabs>
	    </Box>
	    <TabPanel value={value} index={0}>
		{inputs.length > 0 ?
		    <InputList fetchInputs={fetchInputs} addConsumed={addConsumed} inputs={inputs}/>
		    : <Typography>Add consumables in the <b>New</b> tab</Typography>}
	    </TabPanel>
	    <TabPanel value={value} index={1}>
		<InputForm fetchInputs={fetchInputs}/>
	    </TabPanel>
	    <TabPanel value={value} index={2}>
		{props.consumed.length > 0 ?
		    <Consumed consumed={props.consumed} fetchConsumed={props.fetchConsumed}/>
		    : <Typography>Add consumed items in the <b>Consume</b> tab</Typography>}
	    </TabPanel>

	</Box>
    );

}
