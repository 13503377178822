import React, { useEffect, useContext, createContext, useState } from "react";
import {router} from './App';

interface UserContext {
  user: User;
  setUser: (user: User) => void;
}

const initUser = {email: '', gender: '', bac_inflection: 0, metabolic_rate: 0, weight: 0}

export const userContext = createContext<UserContext>({
  user: initUser,
  setUser: (x) => {},
});

const useUser = () => {
	const navigate = router.navigate;
	const {user, setUser} = useContext(userContext);


	const storeUser = (user: User) => {
		const session : Session = {...user, expiration: Date.now() + 30 * 24 * 60 * 60 * 1000};
		window.localStorage.setItem('moderatus-user', JSON.stringify(session))
	};

	const removeStoredUser = () => {
		window.localStorage.removeItem('moderatus-user');
	}

	const addUser = (userObject : User) => {
		storeUser(userObject);
		setUser(userObject);
	};

	const removeUser = () => {
		removeStoredUser();
		setUser(initUser);
		navigate('/');
	};

	return {
		user,
		addUser,
		removeUser
	}

}

export function ProvideAuth({ children }: any) {
	const [user, setUser] = useState<User>(initUser);

	return (
		<userContext.Provider value={{user, setUser}}>
			{children}
		</userContext.Provider>
	);
}

export const useAuth = () => {

	const {user, addUser, removeUser} = useUser();

	useEffect(() => {
		const data = window.localStorage.getItem('moderatus-user');
		if ( data !== null ) {
			const session = JSON.parse(data);
			if (session.expiration > Date.now()) {
				delete session.expiration;
				addUser(session);
			} else {
				window.localStorage.removeItem('moderatus-user');
			}
		}
	}, []);


	const signin = (userObject : User) => {
		addUser(userObject);
	};

	const signout = () => {
		removeUser();
	};

	return {user, signin, signout};
}
