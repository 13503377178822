import Landing from './Landing';
import Update from './Update';
import History from './History';
import AlertPopup from './AlertPopup';
import { ProvideAuth } from './AuthHook';
import React from "react";
import {
	createBrowserRouter,
	RouterProvider
} from "react-router-dom";
import ResponsiveAppBar from './AppBar';

export const router = createBrowserRouter([
	{ path:"/", element:<Landing/>},
	{ path:"update", element:<Update/>},
	{ path:"history", element:<History/>},
]); 

export default function App() {
	return (
		<ProvideAuth>
			<AlertPopup/>
			<ResponsiveAppBar/>
			<RouterProvider router={router}/>
		</ProvideAuth>
	);
}
