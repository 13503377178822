import * as React from 'react';
import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';

const ListItem = styled('li')(({ theme }) => ({
margin: theme.spacing(0.5),
}));

export default function InputList(props: any) {
	const deleteIngredient = async (index: number) => {
		props.deleteIngredient(index)
	}

	return (
			<Box
			sx={{
display: 'flex',
justifyContent: 'flex-start',
flexWrap: 'wrap',
listStyle: 'none',
my: 1,
px:0
}}
component="ul"
>
{props.ingredients.length && props.ingredients.map((data: Input, index: number) => {
		return (
				<ListItem key={index}>
				<Chip
				label={data.volume + 'oz ' + data.percentage + '%'}
				onDelete={() => {deleteIngredient(index)}}
				/>
				</ListItem>
		       );
		})}
</Box>
);
}
