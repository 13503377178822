import React from "react";
import './styles/NeedleGauge.scss';
import GaugeChart from 'react-gauge-chart';
import {calculateWidmark, filterMetabolized, getDuration} from './Utils';
import {userContext} from './AuthHook';
import Box from '@mui/material/Box';

const NeedleGauge = (props: {bac: number}) => {

	const {user} = React.useContext(userContext);
	const one = user.bac_inflection / .3;
	const two = user.bac_inflection + .03 / .3;
	const three = (.3 - (user.bac_inflection + .03))/ .3;
	const compWidth = window.innerWidth >= 720 ? .7 : 1;

	return (<Box display='flex' justifyContent="center" sx={{p:2, width:compWidth}}><GaugeChart style={{display: 'block', height: 'inherit', width: 'inherit'}} hideText={true} arcPadding={.02} animDelay={0} arcsLength={[one, two, three]} colors={['#2dc937', '#e7b416','#cc3232']} percent={props.bac/.3} /></Box>);
}

export default NeedleGauge;
